<template>
  <div class="worship-friends-circle">
    <div class="friends-circle-body">
      <div class="list-item" v-for="index in 10">
        <img class="item-avater" src="../../../assets/images/default-avatar.png">
        <div class="item-content">
          <div class="content-box">
            <div class="box-title">某某某</div>
            <div class="box-circle">与逝者关系：<span>同事</span></div>
          </div>
          <button type="button">删除</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'worship-friends-circle'
  }
</script>

<style lang="scss" scoped>
.worship-friends-circle {
  position: relative;
  z-index: 22;
  min-height: 570px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  .friends-circle-body {
    @include w-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .list-item {
      background: #FFFFFF;
      padding: 11px 13px;
      border: 1px solid #E4E4E4;
      border-radius: 10px;
      margin-top: 20px;
      width: 49%;
      @include w-flex;
      align-items: center;
      .item-avater {
        width: 60px;
        height: 60px;
        background: #53A5D1;
        border-radius: 50%;
        overflow: hidden;
      }
      .item-content {
        @include w-flex;
        flex: 1;
        align-items: center;
        margin-left: 25px;
        justify-content: space-between;
        .box-title {
          font-size: 20px;
          font-weight: 400;
          color: #434343;
          line-height: 26px;
        }
        .box-circle {
          font-size: 16px;
          font-weight: 400;
          color: #434343;
          line-height: 26px;
        }
        button {
          width: 103px;
          height: 40px;
          background: linear-gradient(-90deg, #79A9D9 0%, #3F7DB1 100%);
          border-radius: 20px;
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          border: none;
        }
      }
    }
  }
  &::-webkit-scrollbar {
   display: none; /* Chrome Safari */
 }
}
</style>
